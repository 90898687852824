export interface ResponseIndex {
  data: any;
  message: string;
  status: string;
}

export class ResponseIndex implements ResponseIndex {
  data: any;
  message: string;
  status: string;

  constructor() {
    this.data = null;
    this.message = "";
    this.status = "";
  }
}
